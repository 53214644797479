import { graphql } from 'gatsby';
import * as React from "react"
import Layout from '~/components/Layout';
import FlexibleContent from '~/components/FlexibleContent';
import SEO from 'gatsby-plugin-wpgraphql-seo';

export default function defaultPage({ data: { wpPage } }) {
  return (
    <Layout headerWhite={wpPage.page.page.whiteHeader} backgroundColour={wpPage.page.page.backgroundColour} showLogin={wpPage.page.page.showLogin} footerCtaType={wpPage.page.page.footerCtaType} >
      <SEO post={wpPage} />
      <FlexibleContent content={wpPage.page.page.content}  />
    </Layout>
  )
}

export const defaultPageQuery = graphql`
  query defaultPagePageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      id
      ...SEO
      page {
        page {
          backgroundColour
          whiteHeader
          showLogin
          footerCtaType
          content {
            ... on WpPage_Page_Page_Content_TwoColumns {
              fieldGroupName
              twoColumns {
                alignImage
                backgroundColour {
                  colour
                  colourSize
                  alignColour
                  padding {
                    top
                    bottom
                  }
                }
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText
                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                image {
                  ... Image
                }
                secondImage {
                  ... Image
                }
                thirdImage {
                  ... Image
                }
                alignSecondImage
              }
            }
            ... on WpPage_Page_Page_Content_Testimonial {
              fieldGroupName
              testimonial {
                backgroundColour {
                  colour
                  colourSize
                  alignColour
                  padding {
                    top
                    bottom
                  }
                }
                quote
                fieldGroupName
                author {
                  role
                  name
                  fieldGroupName
                }
                hidetrustpilotwidget
                quoteTagType
                link {
                  ... Button
                }
                innerBackgroundColour
                alignImage
                image {
                  ... Image
                }
              }
            }
            ... on WpPage_Page_Page_Content_Features {
              fieldGroupName
              features {
                layout
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                features {
                  content
                  fieldGroupName
                  heading
                  image {
                    ... Image
                  }
                  link {
                    ... Button
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_FullWidthImage {
              fieldGroupName
              image {
                ... Image
              }
            }
            ... on WpPage_Page_Page_Content_Reviews {
              fieldGroupName
              reviews {
                heading
                reviews {
                  fieldGroupName
                  review
                  stars
                  author {
                    borough
                    fieldGroupName
                    name
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_Faqs {
              fieldGroupName
              faqs {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                faqs {
                  question
                  fieldGroupName
                  answer
                }
                link {
                  ... Button
                }
              }
            }
            ... on WpPage_Page_Page_Content_Usps {
              fieldGroupName
              usps {
                backgroundColour {
                  colour
                  colourSize
                  alignColour
                  padding {
                    top
                    bottom
                  }
                }
                showArrows
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText
                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                usps {
                  alignText
                  content
                  heading
                  fieldGroupName
                  imageSize
                  link {
                    ... Button
                  }
                  image {
                    ... Image
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_Team {
              fieldGroupName
              team {
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                members {
                  image {
                    ... Image
                  }
                  name
                  title
                  linkedinUrl
                }
              }
            }
            ... on WpPage_Page_Page_Content_Press {
              fieldGroupName
              press {
                press {
                  heading
                  link {
                    ... Button
                  }
                  logo {
                    ... Image
                  }
                }
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_LatestResources {
              fieldGroupName
              latestResources {
                resourceType
                customResources {
                  resource {
                    ... on WpPost {
                      id
                      uri
                      title
                      excerpt
                      featuredImage {
                        node {
                          ...Image
                        }
                      }
                    }
                  }
                  
                }
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_Intro {
              fieldGroupName
              intro {
                subheading
                heading
                button {
                  ... Button
                }
              }
            }
            ... on WpPage_Page_Page_Content_Hero {
              fieldGroupName
              hero {
                image {
                  ... Image
                }
                fieldGroupName
                form
                showHelpFeatures
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_TwoColumnFeaturesWImage {
              fieldGroupName
              twoColumnFeaturesWImage {
                fieldGroupName
                image {
                  ... Image
                }
                backgroundColour {
                  colourSize
                  alignColour
                  colour
                  padding {
                    top
                    bottom
                  }
                }
                features {
                  content
                  heading
                  illustration {
                    ... Image
                  }
                }
                alignImage
                imagePadding
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_Awards {
              fieldGroupName
              awards {
                fieldGroupName
                awards {
                  year
                  image {
                    ... Image
                  }
                  heading
                  fieldGroupName
                }
              }
            }
            ... on WpPage_Page_Page_Content_Cta {
              fieldGroupName
              cta {
                illustrationSize
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                illustration {
                  ... Image
                }
                fieldGroupName
              }
            }
            ... on WpPage_Page_Page_Content_Careers {
              fieldGroupName
              careers {
                subheading
                careers {
                  title
                  department
                  externalUrl
                  location {
                    city
                    country
                  }
                }
                fieldGroupName
              }
            }
            ... on WpPage_Page_Page_Content_Values {
              fieldGroupName
              values {
                fieldGroupName
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                values {
                  content
                  fieldGroupName
                  heading
                  illustration {
                    ... Image
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_TestimonialsSlider {
              fieldGroupName
              testimonialsSlider {
                cursorBackgroundColour
                margin {
                  top
                  bottom
                }
                testimonials {
                  testimonial {
                    quote
                    image {
                      ... Image
                    }
                    author {
                      name
                      role
                    }
                    hidetrustpilotwidget
                    quoteTagType
                    link {
                      ... Button
                    }
                    innerBackgroundColour
                    alignImage
                  }
                  fieldGroupName
                }
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                fieldGroupName
              }
            }
            ... on WpPage_Page_Page_Content_TwoColumnFeatures {
              fieldGroupName
              twoColumnFeatures {
                features {
                  content
                  heading
                  illustration {
                    ... Image
                  }
                }
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_TextImageGrid {
              fieldGroupName
              textImageGrid {
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                images {
                  image {
                    ... Image
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_Logos {
              fieldGroupName
              logos {
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                logos {
                  logo {
                    ... Image
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_Quote {
              fieldGroupName
              quote {
                backgroundColour {
                  colourSize
                  alignColour
                  colour
                  padding {
                    top
                    bottom
                  }                }
                quote
                caption
                author {
                  name
                  role
                }
                illustration {
                  ... Image
                }
              }
            }
            ... on WpPage_Page_Page_Content_HeroPricing {
              fieldGroupName
              heroPricing {
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_IntroWImagesParallaxing {
              fieldGroupName
              introWImagesParallaxing {
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                images {
                  image {
                    ... Image
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_HeroSlider {
              fieldGroupName
              heroSlider {
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                images {
                  image {
                    ... Image
                  }
                  location
                }
              }
            }
            ... on WpPage_Page_Page_Content_IntroImages {
              fieldGroupName
              introImages {
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                desktopImage {
                  ... Image
                }
                mobileImage {
                  ... Image
                }
              }
            }
            ... on WpPage_Page_Page_Content_HeroTwoColumn {
              fieldGroupName
              heroTwoColumn {
                backgroundColour
                showArrow
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                image {
                  ... Image
                }
              }
            }
            ... on WpPage_Page_Page_Content_ImageSlider {
              fieldGroupName
              imageSlider {
                images {
                  image {
                    ... Image
                  }
                }
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_ImageUsps {
              fieldGroupName
              imageUsps {
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                usps {
                  heading
                  content
                  image {
                    ... Image
                  }
                }
              }
            }
            ... on WpPage_Page_Page_Content_HeroAbout {
              fieldGroupName
              heroAbout {
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
                illustration {
                  ... Image
                }
                image {
                  ... Image
                }
              }
            }
            ... on WpPage_Page_Page_Content_TextCta {
              fieldGroupName
              textCta {
                textCard {
                  illustration {
                    ... Image
                  }
                  subheading
                  subheadingColor
                  alignText

                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ... Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
